import './App.css';
import { Apps } from '@mui/icons-material';
import { Box, Card, CardContent, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { AppStyles } from './AppStyles';
import { AppsList } from './AppsList';

const appItems = AppsList.map((appItem: any, index: number) =>
  appItem.divider ?
    <Divider />
    :
    <ListItem key={index} disablePadding>
      <ListItemButton component="a" href={appItem.href}>
        <ListItemIcon>
          {appItem.icon}
        </ListItemIcon>
        <ListItemText primary={appItem.title} secondary={appItem.note} />
      </ListItemButton>
    </ListItem>
);

function App() {
  return (
    <Card style={AppStyles.cardStyle}
      sx={{ bgcolor: 'background.paper'}}
    >
      <CardContent>
        <Apps style={AppStyles.logoStyle} fontSize="large" />
      </CardContent>
      <CardContent>
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <nav aria-label="app links">
            <List>
              {appItems}
            </List>
          </nav>
        </Box>
      </CardContent>
      <Box 
        display="flex" justifyContent="center" alignItems="center"
        sx={{ height: '300px', width: '100%', bgcolor: 'black' }}>
            <Typography sx={{color: 'white'}}>
              neo.mitchallen.com
            </Typography>
        </Box>
    </Card>
  );
}

export default App;
