import { Apps, Home, Movie, Palette } from "@mui/icons-material";

export interface AppsListItem {
    title?: string,
    note?: string,
    href?: string,
    icon?: any,
    divider?: boolean,
};

export const AppsList: AppsListItem[] = [
    // clouds-timer.surge.sh
    {
        title: "MP4 Clouds Timer",
        note: "Video clouds timer",
        href: "https://clouds-timer.surge.sh/",
        icon: <Movie />,
    },
    {
        title: "MP4 Clouds Video",
        note: "Video clouds",
        href: "https://clouds-video.surge.sh/",
        icon: <Movie />,
    },
    {
        title: "PNG Shapes",
        note: "Abstract shapes",
        href: "https://png-shapes.surge.sh/",
        icon: <Palette />,
    },
    {
        title: "PNG BW",
        note: "Abstract B&W shapes",
        href: "https://png-bw.surge.sh/",
        icon: <Palette />,
    },
    {
        divider: true,
    },
    {
        title: "apps.mitchallen.com",
        href: "https://apps.mitchallen.com/",
        icon: <Apps />,
    },
    {
        title: "MitchAllen.com",
        href: "https://mitchallen.com/",
        icon: <Home />,
    },
];